import './App.css';
import LandingPage from './LandingPage';

function App() {
  return (
    <div className="App">
      <LandingPage/>
    </div>
  )
}

export default App;
